<template>
  <div class="admin-quick-create-exception">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Header -->
    <div class="page-header">
      <h1 class="page-title">
        <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="goBack"
          icon="left"
        ></a-button>

        <admin-org-indicator></admin-org-indicator>

        {{ title }}
      </h1>
    </div>
    <!-- / Header -->

    <!-- Description -->
    <a-alert
      style="margin-bottom: 25px"
      message="For exceptions further in the future use the Add exception button."
    >
    </a-alert>
    <!-- / Description -->

    <!-- Loading -->
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->

    <!-- Loaded -->
    <div v-if="!isLoading">
      <div class="form">
        <!-- Will Be -->
        <a-form-item label="Will Be">
          <a-radio-group v-model="available">
            <a-radio :value="false">Unavailable</a-radio>
            <a-radio :value="true">Available</a-radio>
          </a-radio-group>
        </a-form-item>
        <!-- / Will Be -->

        <!-- For -->
        <a-form-item :label="`For (${formatter(forTime)})`">
          <a-slider
            v-model="forTime"
            :tip-formatter="(value) => formatter(value)"
            :min="5"
            :max="480"
            :step="5"
          />
        </a-form-item>
        <!-- / For -->
      </div>

      <a-button
        @click.prevent="save"
        class="btn-rounded save-button"
        size="large"
        type="primary"
        >Save</a-button
      >
    </div>
    <!-- / Loaded -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import presences from "../../../../../api/presences";
import availability from "../../../../../api/availability";
// const _ = require("lodash");
import LoadingScreen from "../../../../../components/LoadingScreen.vue";
import moment from "moment";

export default {
  data() {
    return {
      isLoadingPresence: false,
      presence: null,

      isSaving: false,

      available: false,
      forTime: 60,
    };
  },
  components: { LoadingScreen },
  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    } else {
      this.loadPresence();
    }
  },
  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    isLoading() {
      return this.isLoadingPresence;
    },

    title() {
      if (this.isLoadingPresence) {
        return "Loading...";
      }
      if (this.presence) {
        return "New Exception For " + this.presence.displayName;
      }
      return null;
    },

    presenceId() {
      return this.$route.params.presenceId;
    },
  },
  methods: {
    goBack() {
      this.$router.push(
        "/admin/responders/availability/" + this.presenceId + "/exceptions"
      );
    },

    formatter(value) {
      // let hr = Math.floor(Number(value) / 60);
      // let min = value % 60;

      // return String(hr).padStart(2, "0") + ":" + String(min).padStart(2, "0");

       let s = value;
      return (
        (s - (s %= 60)) / 60 + (9 < s ? " hr" : " hr") + (s ? " " + s + " mins" : "")
      );
    },

    loadPresence() {
      let vm = this;
      vm.isLoadingPresence = true;
      vm.presence = null;
      presences
        .getPresence(this.tenantId, this.presenceId)
        .then((r) => {
          vm.isLoadingPresence = false;
          vm.presence = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingPresence = false;
          vm.$message.error("Error loading user");
          vm.goBack();
        });
    },

    save() {
      let vm = this;
      vm.isSaving = true;

      let startDateTime = moment();

      let startInstant = startDateTime.format(
        "YYYY-MM-DD[T]HH:mm:ss.SSSSSSS[Z]"
      );

      let endDateTime = startDateTime.clone().add("minute", this.forTime);

      let endInstant = endDateTime.format("YYYY-MM-DD[T]HH:mm:ss.SSSSSSS[Z]");

      let params = {
        OwnerId: vm.selectedOrganisation.id,
        PresenceId: vm.presenceId,
        StartInstant: startInstant,
        EndInstant: endInstant,
        // StartInstant: "2023-01-01T00:00:00.000000000Z",
        // EndInstant: "2023-05-15T00:00:00.000000000Z",
        Available: this.available,
      };
      availability
        .addOverride(this.tenantId, params)
        .then(() => {
          vm.isSaving = false;
          vm.$message.success("Exception saved successfully");
          vm.goBack();
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error saving exception");
        });
    },
  },
};
</script>

<style lang="scss">
.admin-quick-create-exception {
  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .ant-table {
    background: #fff !important;
  }

  .button-margin-left {
    margin-left: 15px;
  }

  .title {
    margin-bottom: 20px;
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;

    &.margin-bottom {
      margin-bottom: 30px;
    }

    &.margin-bottom-small {
      margin-bottom: 15px;
    }
  }

  .save-button {
    margin-top: 30px;
  }

  .period-wrapper {
    padding-top: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: none !important;
    }

    .period {
      //   padding-top: 35px;
      display: flex;

      .left {
        flex-grow: 1;
        padding-left: 10px;
        padding-right: 50px;
        //   display: flex;
        //   align-items: center;
        //   justify-items: center;

        .range-title {
          display: block;
          margin-bottom: 50px;

          span {
            font-weight: 400;
            display: block;
            margin-top: 5px;
          }
        }
      }

      .right {
        flex-shrink: 1;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>